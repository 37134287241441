/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row } from "antd";

import { required } from "../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

const VehiculosForm = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { id, readonly } = props;

  const CREAR = hasPermission(actions.VehiculosCrear);
  const EDITAR = hasPermission(actions.VehiculosEditar);
  const disabled = readonly || (id === "nuevo" ? !CREAR : !EDITAR);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getForm() {
      if (id === "nuevo") {
        return;
      }

      const response = await http.get(`vehiculos/${id}`);
      if (response) {
        const data = response.data;
        form.setFieldsValue(data);
      }
    }
    getForm();
  }, [id]);

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickSave = async (values) => {
    setLoading(true);

    const response =
      id === "nuevo"
        ? await http.post("vehiculos", values)
        : await http.put(`vehiculos/${id}`, values);

    setLoading(false);

    if (response) {
      await modalSuccess(
        "Vehículo guardado",
        id === "nuevo"
          ? "El vehículo fue creado exitosamente"
          : "El vehículo fue modificado exitosamente"
      );
      navigate(-1);
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onClickSave}>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item label="Interno" name="interno" rules={[required]}>
            <Input maxLength={20} disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Dominio" name="dominio" rules={[required]}>
            <Input maxLength={20} disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Marca" name="marca">
            <Input maxLength={50} disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Modelo" name="modelo">
            <Input maxLength={50} disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>

      <div className="actions">
        <Button type="text" onClick={onClickBack}>
          Volver
        </Button>
        {!disabled && (
          <Button type="primary" htmlType="submit" loading={loading}>
            {!loading && "Guardar"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default VehiculosForm;
