import { useEffect } from "react";
import { Col, Row } from "antd";

import { getToken } from "../../services/security";

import LoginForm from "../../components/auth/login-form";

import image from "./../../assets/images/background.jpg";
import logo from "./../../assets/images/logo-horizontal.png";

import classes from "./Login.module.scss";

const LoginPage = () => {
  useEffect(() => {
    const token = getToken();

    if (token) {
      window.location.replace("/");
    }
  }, []);

  return (
    <Row>
      <Col sm={0} lg={12} xl={14}>
        <div
          className={classes.image}
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className={classes.overlay}>
            <img className={classes.logo} src={logo} alt="logo" />
          </div>
        </div>
      </Col>
      <Col sm={24} lg={12} xl={10}>
        <div className={classes.form}>
          <LoginForm />
        </div>
      </Col>
    </Row>
  );
};

export default LoginPage;
