import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import {
  FiEdit2,
  FiFileText,
  FiPlus,
  FiSearch,
  FiTrash2,
} from "react-icons/fi";

import { TableContextProvider } from "../../store/table-context";
import http from "../../services/http";
import { confirm, modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

import Card from "../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "../../components/ui/filters";
import Table from "../../components/ui/table";
import OrdenesTrabajoEstado from "../../components/ordenes-trabajo/ordenes-trabajo-estado";

const OrdenesTrabajoListPage = () => {
  const navigate = useNavigate();

  const VER = hasPermission(actions.OrdenesTrabajoVer);
  const CREAR = hasPermission(actions.OrdenesTrabajoCrear);
  const EDITAR = hasPermission(actions.OrdenesTrabajoEditar);
  const ELIMINAR = hasPermission(actions.OrdenesTrabajoEliminar);

  const title = "Ordenes de Trabajo";
  const icon = <FiFileText />;
  const breadcrumb = [{ title: "Ordenes de Trabajo", url: "/ordenes-trabajo" }];

  const filters = [
    {
      type: "input",
      label: "N° de Orden",
      name: "numero",
    },
    {
      type: "date",
      label: "Ingreso Desde",
      name: "desde",
      half: true,
    },
    {
      type: "date",
      label: "Ingreso Hasta",
      name: "hasta",
      half: true,
    },
    {
      type: "input",
      label: "Interno",
      name: "interno",
    },
    {
      type: "input",
      label: "N° de Nota",
      name: "nota",
    },
    {
      type: "select",
      label: "Servicio",
      name: "servicio",
      values: [
        {
          value: 1,
          text: "Electricidad",
        },
        {
          value: 2,
          text: "Reparaciones Varias",
        },
        {
          value: 3,
          text: "Estética",
        },
        {
          value: 4,
          text: "Cubiertas y Neumáticos",
        },
        {
          value: 5,
          text: "Lubricantes y Aceites",
        },
        {
          value: 6,
          text: "Reparaciones de Motor",
        },
      ],
    },
  ];

  const columns = [
    { title: "N° de Orden", property: "numero", sortable: true },
    { title: "Ingreso", property: "ingreso", sortable: true },
    { title: "Interno", property: "interno", sortable: true },
    {
      title: "Estado",
      property: "estado",
      sortable: true,
      render: (item) => <OrdenesTrabajoEstado estado={item.estado} />,
    },
    { title: "N° de Nota", property: "nota", sortable: true },
    { title: "Egreso", property: "egreso", sortable: true },
    { title: "Servicio", property: "servicio", sortable: true },
  ];

  const menu = (item) => (
    <Menu>
      {VER && (
        <Menu.Item
          key="1"
          icon={<FiSearch />}
          onClick={() => onClickView(item)}
        >
          Ver
        </Menu.Item>
      )}
      {EDITAR && (
        <Menu.Item key="2" icon={<FiEdit2 />} onClick={() => onClickEdit(item)}>
          Editar
        </Menu.Item>
      )}
      {ELIMINAR && (
        <Menu.Item
          key="3"
          icon={<FiTrash2 />}
          onClick={() => onClickDelete(item)}
        >
          Eliminar
        </Menu.Item>
      )}
    </Menu>
  );

  const onClickAdd = () => {
    navigate("/ordenes-trabajo/nuevo");
  };

  const onClickView = (item) => {
    navigate(`/ordenes-trabajo/${item.id}?view=readonly`);
  };

  const onClickEdit = (item) => {
    navigate(`/ordenes-trabajo/${item.id}`);
  };

  const onClickDelete = async (item) => {
    const confirmed = await confirm(
      "Eliminar orden de trabajo",
      `¿Esta seguro que desea eliminar la orden de trabajo ${item.numero}?`
    );

    if (!confirmed) {
      return;
    }

    const response = await http.delete(`ordenes-trabajo/${item.id}`);
    if (response) {
      await modalSuccess(
        "Orden de trabajo eliminada",
        "La orden de trabajo fue eliminada exitosamente"
      );
      navigate(0);
    }
  };

  const buttons = [
    {
      title: "Agregar Orden",
      text: <FiPlus />,
      type: "primary",
      onClick: onClickAdd,
      visible: CREAR,
    },
  ];

  return (
    <TableContextProvider>
      <Card>
        <Header
          title={title}
          icon={icon}
          breadcrumb={breadcrumb}
          showFilters
          buttons={buttons}
        />
        <Filters fields={filters} />
        <Table
          id="table-ordenes-trabajo"
          columns={columns}
          menu={menu}
          url="/ordenes-trabajo"
          orderBy="id"
          orderDirection="descending"
        />
      </Card>
    </TableContextProvider>
  );
};

export default OrdenesTrabajoListPage;
