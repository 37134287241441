import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Dropdown, Menu } from "antd";
import { FiCalendar, FiEdit, FiPlus, FiSearch, FiTrash2 } from "react-icons/fi";

import http from "./../../services/http";
import { confirm, modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

import { TableContextProvider } from "../../store/table-context";

import Card from "../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "../../components/ui/filters";
import Calendar from "../../components/ui/calendar";
import TurnosForm from "./../../components/turnos/turnos-form";

const TurnosListPage = () => {
  const navigate = useNavigate();

  const VER = hasPermission(actions.TurnosVer);
  const CREAR = hasPermission(actions.TurnosCrear);
  const EDITAR = hasPermission(actions.TurnosEditar);
  const ELIMINAR = hasPermission(actions.TurnosEliminar);

  const title = "Turnos";
  const icon = <FiCalendar />;
  const breadcrumb = [{ title: "Turnos", url: "/turnos" }];

  const filters = [
    {
      type: "input",
      label: "Interno",
      name: "interno",
    },
    {
      type: "input",
      label: "Ubicacion",
      name: "ubicacion",
    },
    {
      type: "input",
      label: "Servicio",
      name: "servicio",
    },
  ];

  const [formVisible, setFormVisible] = useState(false);
  const [formReadonly, setFormReadonly] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTurno, setSelectedTurno] = useState();

  const onClickAdd = () => {
    setFormVisible(true);
  };

  const onClickDate = (value) => {
    setSelectedDate(value);
    setFormVisible(true);
  };

  const onClickTurno = (event) => {
    event.stopPropagation();
  };

  const onCloseForm = () => {
    setSelectedDate();
    setSelectedTurno();
    setFormReadonly(false);
    setFormVisible(false);
  };

  const onClickView = (event, item) => {
    event.domEvent.stopPropagation();
    setSelectedTurno(item);
    setFormReadonly(true);
    setFormVisible(true);
  };

  const onClickEdit = (event, item) => {
    event.domEvent.stopPropagation();
    setSelectedTurno(item);
    setFormVisible(true);
  };

  const onClickDelete = async (event, item) => {
    event.domEvent.stopPropagation();

    const confirmed = await confirm(
      "Eliminar turno",
      `¿Esta seguro que desea eliminar el turno?`
    );

    if (!confirmed) {
      return;
    }

    const response = await http.delete(`turnos/${item.id}`);
    if (response) {
      await modalSuccess(
        "Turno eliminado",
        "El turno fue eliminado exitosamente"
      );
      navigate(0);
    }
  };

  const renderMenu = (item) => (
    <Menu>
      {VER && (
        <Menu.Item
          key="1"
          icon={<FiSearch />}
          onClick={(event) => onClickView(event, item)}
        >
          Ver
        </Menu.Item>
      )}
      {EDITAR && (
        <Menu.Item
          key="2"
          icon={<FiEdit />}
          onClick={(event) => onClickEdit(event, item)}
        >
          Editar
        </Menu.Item>
      )}
      {ELIMINAR && (
        <Menu.Item
          key="3"
          icon={<FiTrash2 />}
          onClick={(event) => onClickDelete(event, item)}
        >
          Eliminar
        </Menu.Item>
      )}
    </Menu>
  );

  const renderTurno = (turno, index) => {
    return (
      <Dropdown
        overlay={() => renderMenu(turno)}
        placement="bottomCenter"
        trigger="click"
        arrow
      >
        <div className="calendar-event" key={index} onClick={onClickTurno}>
          <div className="calendar-icon" />
          {turno.interno}
        </div>
      </Dropdown>
    );
  };

  const buttons = [
    {
      title: "Agregar Turno",
      text: <FiPlus />,
      type: "primary",
      onClick: onClickAdd,
      visible: CREAR,
    },
  ];

  return (
    <TableContextProvider>
      <Card>
        <Header
          title={title}
          icon={icon}
          breadcrumb={breadcrumb}
          showFilters
          buttons={buttons}
        />
        <Filters fields={filters} />
        <Calendar
          id="calendar-turnos"
          url="/turnos"
          onClickDate={onClickDate}
          renderEvent={renderTurno}
        />
        <TurnosForm
          visible={formVisible}
          readonly={formReadonly}
          date={selectedDate}
          turno={selectedTurno}
          onClose={onCloseForm}
        />
      </Card>
    </TableContextProvider>
  );
};

export default TurnosListPage;
