import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Col, Row } from "antd";
import { FiFileText } from "react-icons/fi";

import { actions, getAction, hasPermission } from "../../services/security";

import Card from "../../components/ui/card";
import Header from "../../components/ui/header";
import OrdenesTrabajoForm from "./../../components/ordenes-trabajo/ordenes-trabajo-form";

const OrdenesTrabajoDetailPage = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const CREAR = hasPermission(actions.OrdenesTrabajoCrear);
  const EDITAR = hasPermission(actions.OrdenesTrabajoEditar);
  const readonly = searchParams.get("view") === "readonly";

  const action = getAction(id, CREAR, EDITAR, readonly);
  const title = `${action} Orden de Trabajo`;
  const icon = <FiFileText />;
  const breadcrumb = [
    { title: "Ordenes de Trabajo", url: "/ordenes-trabajo" },
    { title: action, url: `/ordenes-trabajo/${id}` },
  ];

  return (
    <Row gutter={[20]}>
      <Col xs={24} lg={24}>
        <Card>
          <Header title={title} icon={icon} breadcrumb={breadcrumb} />
          <OrdenesTrabajoForm id={id} readonly={readonly} />
        </Card>
      </Col>
    </Row>
  );
};

export default OrdenesTrabajoDetailPage;
