/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row } from "antd";

import { required } from "./../../services/forms";
import http from "./../../services/http";
import { modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

import PerfilesActions from "./perfiles-actions";

const PerfilesForm = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { id, readonly } = props;

  const CREAR = hasPermission(actions.PerfilesCrear);
  const EDITAR = hasPermission(actions.PerfilesEditar);
  const disabled = readonly || (id === "nuevo" ? !CREAR : !EDITAR);

  const [loading, setLoading] = useState(false);
  const [permisos, setPermisos] = useState([]);

  useEffect(() => {
    async function getForm() {
      if (id === "nuevo") {
        return;
      }

      let response = await http.get(`perfiles/${id}`);
      if (response) {
        const data = response.data;
        setPermisos(data.permisos);
        form.setFieldsValue(data);
      }
    }
    getForm();
  }, [id]);

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickSave = async (values) => {
    values.permisos = permisos;

    setLoading(true);

    const response =
      id === "nuevo"
        ? await http.post("perfiles", values)
        : await http.put(`perfiles/${id}`, values);

    setLoading(false);

    if (response) {
      await modalSuccess(
        "Perfil guardado",
        id === "nuevo"
          ? "El perfil fue creado exitosamente"
          : "El perfil fue modificado exitosamente"
      );
      navigate(-1);
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onClickSave}>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={50} disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            label="Descripción"
            name="descripcion"
            className="textarea"
          >
            <Input.TextArea showCount maxLength={500} disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <PerfilesActions
            selected={permisos}
            updateSelected={setPermisos}
            disabled={disabled}
          />
        </Col>
      </Row>

      <div className="actions">
        <Button type="text" onClick={onClickBack}>
          Volver
        </Button>
        {!disabled && (
          <Button type="primary" htmlType="submit" loading={loading}>
            {!loading && "Guardar"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default PerfilesForm;
