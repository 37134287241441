import axios from "axios";
import { getToken } from "./security";
import { modalError, modalWarning } from "./notifications";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response === undefined || error.response?.status === 404) {
      console.log("Warning: API down");
      modalError("Error", "No se pudo establecer conexion con el servidor");
      return false;
    }

    if (error.response.status === 401) {
      console.log("Warning: Acceso no autorizado");
      localStorage.removeItem("token");
      window.location.replace("/auth/login");
      return false;
    }

    if (error.response.status === 422) {
      console.log("Warning:", error.response.data.description);
      modalWarning("Advertencia", error.response.data.description);
      return false;
    }

    if (error.response.status === 500) {
      console.error("Error:", error);
      modalError(
        "Error",
        "Ha ocurrido un error inesperado. Por favor intente nuevamente."
      );
      return false;
    }
  }
);

const httpGet = async (url) => {
  return await axios.get(url, {
    headers: { authorization: getToken() },
  });
};

const httpPost = async (url, data) => {
  return await axios.post(url, data, {
    headers: { authorization: getToken() },
  });
};

const httpPatch = async (url, data = {}) => {
  return await axios.patch(url, data, {
    headers: { authorization: getToken() },
  });
};

const httpPut = async (url, data) => {
  return await axios.put(url, data, {
    headers: { authorization: getToken() },
  });
};

const httpDelete = async (url) => {
  return await axios.delete(url, {
    headers: { authorization: getToken() },
  });
};

const http = {
  get: httpGet,
  post: httpPost,
  patch: httpPatch,
  put: httpPut,
  delete: httpDelete,
};

export default http;
