/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";

import { parseToDate, required } from "../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

const OrdenesTrabajoForm = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { id, readonly } = props;

  const [vehiculos, setVehiculos] = useState([]);

  const CREAR = hasPermission(actions.OrdenesTrabajoCrear);
  const EDITAR = hasPermission(actions.OrdenesTrabajoEditar);
  const disabled = readonly || (id === "nuevo" ? !CREAR : !EDITAR);

  const [loading, setLoading] = useState(false);
  const [estado, setEstado] = useState();

  useEffect(() => {
    async function getForm() {
      form.setFieldsValue({ numero: "-" });

      let response = await http.get("vehiculos");
      if (response) {
        const data = response.data;
        setVehiculos(data.list);
      }

      if (id === "nuevo") {
        return;
      }

      response = await http.get(`ordenes-trabajo/${id}`);
      if (response) {
        const data = response.data;
        data.ingreso = parseToDate(data.ingreso);
        data.egreso = parseToDate(data.egreso);
        form.setFieldsValue(data);
        setEstado(data.estado);
      }
    }
    getForm();
  }, [id]);

  const onChangeEstado = (value) => {
    setEstado(value);
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickSave = async (values) => {
    setLoading(true);

    const response =
      id === "nuevo"
        ? await http.post("ordenes-trabajo", values)
        : await http.put(`ordenes-trabajo/${id}`, values);

    setLoading(false);

    if (response) {
      await modalSuccess(
        "Orden de trabajo guardada",
        id === "nuevo"
          ? "La orden de trabajo fue creada exitosamente"
          : "La orden de trabajo fue modificada exitosamente"
      );
      navigate(-1);
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onClickSave}>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item label="N° Nota" name="nota">
            <Input disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Vehículo" name="vehiculo" rules={[required]}>
            <Select showSearch optionFilterProp="children" disabled={disabled}>
              {vehiculos.map((option, index) => (
                <Select.Option key={index} value={option.id}>
                  {option.interno}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item label="Ingreso" name="ingreso" rules={[required]}>
            <DatePicker format="DD/MM/YYYY" disabled={disabled} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Egreso"
            name="egreso"
            rules={estado === 3 ? [required] : []}
          >
            <DatePicker format="DD/MM/YYYY" disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Estado" name="estado" rules={[required]}>
            <Select
              showSearch
              optionFilterProp="children"
              disabled={disabled}
              onChange={onChangeEstado}
            >
              <Select.Option value={1}>Pendiente</Select.Option>
              <Select.Option value={2}>En Proceso</Select.Option>
              <Select.Option value={3}>Finalizada</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Responsable" name="responsable">
            <Input disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Ubicación" name="ubicacion">
            <Input disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Kilometraje" name="kilometraje" rules={[required]}>
            <InputNumber disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Servicio" name="servicio" rules={[required]}>
            <Select showSearch optionFilterProp="children" disabled={disabled}>
              <Select.Option value={1}>Electricidad</Select.Option>
              <Select.Option value={2}>Reparaciones Varias</Select.Option>
              <Select.Option value={3}>Estética</Select.Option>
              <Select.Option value={4}>Cubiertas y Neumáticos</Select.Option>
              <Select.Option value={5}>Lubricantes y Aceites</Select.Option>
              <Select.Option value={6}>Reparaciones de Motor</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Observaciones" name="observaciones">
            <Input.TextArea
              maxLength={500}
              style={{
                resize: "none",
              }}
              autoSize={{
                minRows: 3,
                maxRows: 6,
              }}
              disabled={disabled}
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Origen de Repuestos" name="origenRepuestos">
            <Select showSearch optionFilterProp="children" disabled={disabled}>
              <Select.Option value={1}>Depósito</Select.Option>
              <Select.Option value={2}>Lax</Select.Option>
              <Select.Option value={3}>Edenred</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Repuestos" name="repuestos">
            <Input.TextArea
              maxLength={500}
              style={{
                resize: "none",
              }}
              autoSize={{
                minRows: 3,
                maxRows: 6,
              }}
              disabled={disabled}
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Chofer" name="chofer">
            <Input disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Jefe de Taller" name="jefeTaller">
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>

      <div className="actions">
        <Button type="text" onClick={onClickBack}>
          Volver
        </Button>
        {!disabled && (
          <Button type="primary" htmlType="submit" loading={loading}>
            {!loading && "Guardar"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default OrdenesTrabajoForm;
