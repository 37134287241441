/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row } from "antd";
import ReactJson from "react-json-view";

import http from "../../services/http";

import classes from "./auditoria-form.module.scss";

const AuditoriaForm = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { id } = props;

  const [json, setJson] = useState({});

  useEffect(() => {
    async function getForm() {
      const response = await http.get(`auditorias/${id}`);
      if (response) {
        const data = response.data;
        form.setFieldsValue(data);
        const nJson = data.contenido ? JSON.parse(data.contenido) : {};
        nJson.Headers = nJson.Headers ? JSON.parse(nJson.Headers) : {};
        nJson.Body = nJson.Body ? JSON.parse(nJson.Body) : {};
        setJson(nJson);
      }
    }
    getForm();
  }, [id]);

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <Form layout="vertical" form={form}>
      <Row gutter={16}>
        <Col xs={12}>
          <Form.Item label="Fecha" name="fecha">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Hora" name="hora">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item label="Usuario" name="usuario">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item label="Acción" name="accion">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Contenido">
            <div className={classes.viewer}>
              <ReactJson
                src={json}
                name={false}
                enableClipboard={false}
                displayDataTypes={false}
              />
            </div>
          </Form.Item>
        </Col>
      </Row>

      <div className="actions">
        <Button type="text" onClick={onClickBack}>
          Volver
        </Button>
      </div>
    </Form>
  );
};

export default AuditoriaForm;
