import { useParams, useSearchParams } from "react-router-dom";
import { FiShield } from "react-icons/fi";

import { actions, getAction, hasPermission } from "../../services/security";

import Card from "./../../components/ui/card";
import PerfilesForm from "../../components/perfiles/perfiles-form";
import Header from "./../../components/ui/header";

const PerfilesDetailPage = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const CREAR = hasPermission(actions.PerfilesCrear);
  const EDITAR = hasPermission(actions.PerfilesEditar);
  const readonly = searchParams.get("view") === "readonly";

  const action = getAction(id, CREAR, EDITAR, readonly);
  const title = `${action} Perfil`;
  const icon = <FiShield />;
  const breadcrumb = [
    { title: "Perfiles", url: "/perfiles" },
    { title: action, url: `/perfiles/${id}` },
  ];

  return (
    <Card>
      <Header title={title} icon={icon} breadcrumb={breadcrumb} />
      <PerfilesForm id={id} readonly={readonly} />
    </Card>
  );
};

export default PerfilesDetailPage;
