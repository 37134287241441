import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES";
import "moment/locale/es";

import LayoutPage from "./pages/Layout";
import LoginPage from "./pages/Auth/Login";
import ResetPasswordPage from "./pages/Auth/ResetPassword";
import UpdatePasswordPage from "./pages/Auth/UpdatePassword";
import InicioPage from "./pages/Inicio/Inicio";
import VehiculosListPage from "./pages/Vehiculos/VehiculosList";
import VehiculosDetailPage from "./pages/Vehiculos/VehiculosDetail";
import OrdenesTrabajoListPage from "./pages/OrdenesTrabajo/OrdenesTrabajoList";
import OrdenesTrabajoDetailPage from "./pages/OrdenesTrabajo/OrdenesTrabajoDetail";
import TurnosListPage from "./pages/Turnos/TurnosList";
import PerfilesListPage from "./pages/Perfiles/PerfilesList";
import PerfilesDetailPage from "./pages/Perfiles/PerfilesDetail";
import UsuariosListPage from "./pages/Usuarios/UsuariosList";
import UsuariosDetailPage from "./pages/Usuarios/UsuariosDetail";
import AuditoriasListPage from "./pages/Auditorias/AuditoriasList";
import AuditoriasDetailPage from "./pages/Auditorias/AuditoriasDetail";

import "./app.scss";
import "./styles/alerts.scss";
import "./styles/buttons.scss";
import "./styles/calendar.scss";
import "./styles/datepickers.scss";
import "./styles/forms.scss";
import "./styles/inputs.scss";
import "./styles/modals.scss";
import "./styles/notifications.scss";
import "./styles/pagination.scss";
import "./styles/selects.scss";
import "./styles/switches.scss";
import "./styles/tabs.scss";
import "./styles/tags.scss";

function App() {
  return (
    <ConfigProvider locale={esES}>
      <Router>
        <Routes>
          <Route path="auth/login" element={<LoginPage />} />
          <Route path="auth/reset" element={<ResetPasswordPage />} />
          <Route path="auth/update" element={<UpdatePasswordPage />} />
          <Route path="/" element={<LayoutPage />}>
            <Route path="vehiculos/:id" element={<VehiculosDetailPage />} />
            <Route path="vehiculos" element={<VehiculosListPage />} />
            <Route
              path="ordenes-trabajo/:id"
              element={<OrdenesTrabajoDetailPage />}
            />
            <Route
              path="ordenes-trabajo"
              element={<OrdenesTrabajoListPage />}
            />
            <Route path="turnos" element={<TurnosListPage />} />
            <Route path="perfiles/:id" element={<PerfilesDetailPage />} />
            <Route path="perfiles" element={<PerfilesListPage />} />
            <Route path="usuarios/:id" element={<UsuariosDetailPage />} />
            <Route path="usuarios" element={<UsuariosListPage />} />
            <Route path="auditorias/:id" element={<AuditoriasDetailPage />} />
            <Route path="auditorias" element={<AuditoriasListPage />} />
            <Route path="" exact element={<InicioPage />} />
          </Route>
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
