import React, { useEffect, useState } from "react";

import { confirm } from "../../services/notifications";
import { getTokenDecoded } from "../../services/security";

import classes from "./navbar.module.scss";

import icon from "./../../assets/images/user.png";

const Navbar = () => {
  const [usuario, setUsuario] = useState();

  useEffect(() => {
    const token = getTokenDecoded();
    setUsuario(token.name);
  }, []);

  const onClickLogout = async () => {
    const confirmed = await confirm(
      `Cerrar sesión`,
      `¿Esta seguro que desea cerrar la sesión?`
    );

    if (!confirmed) {
      return;
    }

    localStorage.removeItem("token");
    window.location.replace("/auth/login");
  };

  return (
    <React.Fragment>
      <div className={classes.navbar}>
        <div className={classes.user}>
          <div className={classes.data}>
            <div className={classes.name}>{usuario}</div>
            <div className={classes.logout} onClick={onClickLogout}>
              Cerrar sesión
            </div>
          </div>
          <img className={classes.image} src={icon} alt="user" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
