import moment from "moment";

export const required = {
  required: true,
  message: "Este campo es obligatorio",
};

export const compare = (input, message) => {
  return ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue(input) === value) {
        return Promise.resolve();
      }

      return Promise.reject(message);
    },
  });
};

export const range = (min, max) => {
  return () => ({
    validator(rule, value) {
      const number = parseFloat(value);
      if (number >= min && number <= max) {
        return Promise.resolve();
      }
      return Promise.reject(`El valor debe estar entre ${min} y ${max}`);
    },
  });
};

export const positive = {
  message: "El campo debe ser mayor o igual a 0",
  validator: (_, value) => {
    if (value < 0) {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  },
};

export const guid = {
  message: "El campo debe cumplir con el formato de un GUID",
  validator: (_, value) => {
    const regex =
      /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/i;

    if (regex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
};

export const email = {
  pattern: /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/,
  message: `Debe ingresar un email válido`,
};

export const cuit = {
  pattern: /^((2[034567]|3[034])\d{9}|(2[034567]|3[034])-\d{8}-\d)$/,
  message: `Debe ingresar un CUIT válido`,
};

export const parseDate = (value) => {
  return value === undefined || value === null
    ? undefined
    : value.format("DD/MM/YYYY");
};

export const parseToDate = (value) => {
  return value === undefined || value === null
    ? undefined
    : moment(value, "DD/MM/YYYY");
};

export const firstDayOfMonth = () => {
  const date = moment();
  return moment(`${date.year()}-${date.month() + 1}-01`, "YYYY-MM-DD");
};

export const currentMonth = () => {
  return moment().month() + 1;
};

export const today = () => {
  return moment();
};
