/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";

import { parseToDate, required } from "../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

import TableContext from "../../store/table-context";

const TurnosForm = (props) => {
  const [form] = Form.useForm();
  const context = useContext(TableContext);

  const { visible, readonly, date, turno, onClose } = props;

  const [vehiculos, setVehiculos] = useState([]);

  const CREAR = hasPermission(actions.TurnosCrear);
  const EDITAR = hasPermission(actions.TurnosEditar);
  const disabled = readonly ? true : !turno ? !CREAR : !EDITAR;

  const action = readonly ? "Ver" : !turno ? "Crear" : "Editar";
  const title = `${action} Turno`;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getForm() {
      if (!visible) {
        return;
      }

      let response = await http.get("vehiculos");
      if (response) {
        const data = response.data;
        setVehiculos(data.list);
      }

      if (date) {
        form.resetFields();
        form.setFieldsValue({ fecha: date });
        return;
      }

      const values = { ...turno };
      values.fecha = parseToDate(values.fecha);
      form.setFieldsValue(values);
    }
    getForm();
  }, [visible]);

  const onClickBack = () => {
    onClose();
  };

  const onClickSave = async (values) => {
    setLoading(true);

    const response = !turno
      ? await http.post("turnos", values)
      : await http.put(`turnos/${turno.id}`, values);

    setLoading(false);

    if (response) {
      await modalSuccess(
        "Turno guardado",
        !turno
          ? "El turno fue creado exitosamente"
          : "El turno fue modificado exitosamente"
      );
      context.updateReload();
      onClose();
    }
  };

  return (
    <Modal open={visible} title={title} footer={null} onCancel={onClose}>
      <Form layout="vertical" form={form} onFinish={onClickSave}>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Fecha" name="fecha" rules={[required]}>
              <DatePicker
                format="DD/MM/YYYY"
                allowClear={false}
                disabled={disabled}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Vehículo" name="vehiculo" rules={[required]}>
              <Select
                showSearch
                optionFilterProp="children"
                disabled={disabled}
              >
                {vehiculos.map((option, index) => (
                  <Select.Option key={index} value={option.id}>
                    {option.interno}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Ubicación" name="ubicacion" rules={[required]}>
              <Input disabled={disabled} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Servicio" name="servicio" rules={[required]}>
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>

        <div className="actions">
          <Button type="text" onClick={onClickBack}>
            Cancelar
          </Button>
          {!disabled && (
            <Button type="primary" htmlType="submit" loading={loading}>
              {!loading && "Guardar"}
            </Button>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default TurnosForm;
