import React from "react";
import { Tag } from "antd";

const OrdenesTrabajoEstado = (props) => {
  const { estado } = props;
  let color = "";
  let text = "";

  switch (estado) {
    case 1:
      color = "red";
      text = "Pendiente";
      break;

    case 2:
      color = "gold";
      text = "En Proceso";
      break;

    case 3:
      color = "green";
      text = "Finalizada";
      break;

    default:
      return <React.Fragment />;
  }

  return <Tag color={color}>{text}</Tag>;
};

export default OrdenesTrabajoEstado;
