import jwt from "jwt-decode";

export const getToken = () => {
  const token = localStorage.getItem("token");
  return token ? `Bearer ${token}` : undefined;
};

export const getTokenDecoded = () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return undefined;
  }

  return jwt(token);
};

export const actions = {
  VehiculosVer: 100000,
  VehiculosCrear: 100001,
  VehiculosEditar: 100002,
  VehiculosEliminar: 100003,
  VehiculosHistorialVer: 100004,

  OrdenesTrabajoVer: 101000,
  OrdenesTrabajoCrear: 101001,
  OrdenesTrabajoEditar: 101002,
  OrdenesTrabajoEliminar: 101003,
  OrdenesTrabajoImprimir: 101004,

  TurnosVer: 102000,
  TurnosCrear: 102001,
  TurnosEditar: 102002,
  TurnosEliminar: 102003,

  UsuariosVer: 103000,
  UsuariosCrear: 103001,
  UsuariosEditar: 103002,
  UsuariosEliminar: 103003,

  PerfilesVer: 104000,
  PerfilesCrear: 104001,
  PerfilesEditar: 104002,
  PerfilesEliminar: 104003,

  AuditoriasVer: 105000,
};

export const hasPermission = (action) => {
  const token = localStorage.getItem("token");

  if (!token) {
    return false;
  }

  const payload = jwt(token);
  if (!payload.actions) {
    localStorage.removeItem("token");
    window.location.replace("/auth/login");
  }

  const actions = JSON.parse(payload.actions);
  return actions.includes(action);
};

export const getAction = (id, create, edit, readonly) => {
  if (readonly) {
    return "Ver";
  }

  if (id === "nuevo") {
    return create ? "Crear" : "Ver";
  }

  return edit ? "Editar" : "Ver";
};
