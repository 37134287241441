import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Col, Row } from "antd";
import { BsCarFront } from "react-icons/bs";

import { actions, getAction, hasPermission } from "../../services/security";

import Card from "../../components/ui/card";
import Header from "../../components/ui/header";
import VehiculosForm from "./../../components/vehiculos/vehiculos-form";
import VehiculosHistorial from "./../../components/vehiculos/vehiculos-historial";

const VehiculosDetailPage = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const CREAR = hasPermission(actions.VehiculosCrear);
  const EDITAR = hasPermission(actions.VehiculosEditar);
  const readonly = searchParams.get("view") === "readonly";

  const action = getAction(id, CREAR, EDITAR, readonly);
  const title = `${action} Vehículo`;
  const icon = <BsCarFront />;
  const breadcrumb = [
    { title: "Vehículos", url: "/vehiculos" },
    { title: action, url: `/vehiculos/${id}` },
  ];

  return (
    <Row gutter={[20]}>
      <Col xs={24} lg={24}>
        <Card>
          <Header title={title} icon={icon} breadcrumb={breadcrumb} />
          <VehiculosForm id={id} readonly={readonly} />
        </Card>
      </Col>

      <Col xs={24} lg={24}>
        <Card>
          <VehiculosHistorial id={id} />
        </Card>
      </Col>
    </Row>
  );
};

export default VehiculosDetailPage;
