import { Form, Switch } from "antd";

import { actions } from "../../services/security";

import classes from "./perfiles-actions.module.scss";

const PerfilesActions = (props) => {
  const { selected, updateSelected, disabled } = props;

  const modulos = [
    {
      title: "Vehículos",
      actions: [
        {
          title: "Ver",
          value: actions.VehiculosVer,
        },
        {
          title: "Crear",
          value: actions.VehiculosCrear,
        },
        {
          title: "Editar",
          value: actions.VehiculosEditar,
        },
        {
          title: "Eliminar",
          value: actions.VehiculosEliminar,
        },
        {
          title: "Ver Historial",
          value: actions.VehiculosHistorialVer,
        },
      ],
    },

    {
      title: "Ordenes de Trabajo",
      actions: [
        {
          title: "Ver",
          value: actions.OrdenesTrabajoVer,
        },
        {
          title: "Crear",
          value: actions.OrdenesTrabajoCrear,
        },
        {
          title: "Editar",
          value: actions.OrdenesTrabajoEditar,
        },
        {
          title: "Eliminar",
          value: actions.OrdenesTrabajoEliminar,
        },
      ],
    },

    {
      title: "Turnos",
      actions: [
        {
          title: "Ver",
          value: actions.TurnosVer,
        },
        {
          title: "Crear",
          value: actions.TurnosCrear,
        },
        {
          title: "Editar",
          value: actions.TurnosEditar,
        },
        {
          title: "Eliminar",
          value: actions.TurnosEliminar,
        },
      ],
    },

    {
      title: "Perfiles",
      actions: [
        {
          title: "Ver",
          value: actions.PerfilesVer,
        },
        {
          title: "Crear",
          value: actions.PerfilesCrear,
        },
        {
          title: "Editar",
          value: actions.PerfilesEditar,
        },
        {
          title: "Eliminar",
          value: actions.PerfilesEliminar,
        },
      ],
    },

    {
      title: "Usuarios",
      actions: [
        {
          title: "Ver",
          value: actions.UsuariosVer,
        },
        {
          title: "Crear",
          value: actions.UsuariosCrear,
        },
        {
          title: "Editar",
          value: actions.UsuariosEditar,
        },
        {
          title: "Eliminar",
          value: actions.UsuariosEliminar,
        },
      ],
    },

    {
      title: "Auditorias",
      actions: [{ title: "Ver", value: actions.AuditoriasVer }],
    },
  ];

  const onChangeModule = (module) => {
    const actions = module.actions.map((action) => action.value);

    const checked =
      module.actions.filter((action) => selected.indexOf(action.value) > -1)
        .length === module.actions.length;

    let nSelected = [
      ...selected.filter((action) => actions.indexOf(action) < 0),
    ];

    if (checked) {
      updateSelected(nSelected);
      return;
    }

    nSelected = nSelected.concat(actions);
    updateSelected(nSelected);
  };

  const onChangeAction = (action) => {
    if (disabled) {
      return;
    }

    let nSelected = [...selected];
    const index = nSelected.indexOf(action.value);
    const checked = index > -1;

    if (checked) {
      nSelected.splice(index, 1);
      updateSelected(nSelected);
      return;
    }

    nSelected.push(action.value);
    updateSelected(nSelected);
  };

  return (
    <Form.Item label="Permisos" name="permisos">
      <div className={classes.actions}>
        {modulos.map((module, i) => (
          <div key={i} className={classes.module}>
            <div className={classes.header}>
              <div className={classes.title}>{module.title}</div>
              <Switch
                checked={
                  module.actions.filter((x) => selected.indexOf(x.value) > -1)
                    .length === module.actions.length
                }
                onChange={() => onChangeModule(module)}
              />
            </div>
            <div className={classes.list}>
              {module.actions.map((action, j) => (
                <div key={j} className={classes.item}>
                  <Switch
                    size="small"
                    checked={selected.indexOf(action.value) > -1}
                    onChange={() => onChangeAction(action)}
                  />
                  <div className={classes.title}>{action.title}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Form.Item>
  );
};

export default PerfilesActions;
