/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Popover, Tag } from "antd";

import http from "../../services/http";
import { actions, hasPermission } from "../../services/security";

import classes from "./vehiculos-historial.module.scss";

import icon from "./../../assets/images/mechanic.png";
import OrdenesTrabajoEstado from "./../ordenes-trabajo/ordenes-trabajo-estado";

const VehiculosHistorial = (props) => {
  const { id } = props;

  const VER = hasPermission(actions.VehiculosHistorialVer);

  const [historial, setHistorial] = useState([]);

  useEffect(() => {
    async function getForm() {
      if (id === "nuevo" || !VER) {
        return;
      }

      const response = await http.get(`vehiculos/${id}/historial`);
      if (response) {
        const data = response.data;
        setHistorial(data);
      }
    }
    getForm();
  }, [id]);

  if (id === "nuevo") {
    return <React.Fragment />;
  }

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.icon}>
          <img src={icon} alt="emails" />
        </div>
        <div className={classes.data}>
          <div className={classes.title}>Historial de Mantenimiento</div>
          <div className={classes.description}>
            Lista de ordenes de trabajo realizadas al vehículo
          </div>
        </div>
      </div>

      <table className={classes.table}>
        <thead>
          <tr>
            <th>N° de Nota</th>
            <th>Ingreso</th>
            <th>Servicio</th>
            <th>Estado</th>
            <th>Kilometraje</th>
            <th>Egreso</th>
            <th>Jefe del Taller</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {historial.length === 0 && (
            <tr>
              <td colSpan={8}>
                <div className={classes.empty}>
                  <div className={classes.title}>Sin resultados</div>
                  <div className={classes.description}>
                    No se encontró ningún resultado
                  </div>
                </div>
              </td>
            </tr>
          )}

          {historial.map((historico, index) => (
            <tr key={index}>
              <td>{historico.nota}</td>
              <td>{historico.ingreso}</td>
              <td>{historico.servicio}</td>
              <td>
                <OrdenesTrabajoEstado estado={historico.estado} />
              </td>
              <td>{historico.kilometraje}</td>
              <td>{historico.egreso}</td>
              <td>{historico.jefeTaller}</td>
              <td>
                <Popover
                  placement="topRight"
                  title="Repuestos"
                  content={historico.repuestos}
                >
                  <Tag color="blue" style={{ cursor: "pointer" }}>
                    Ver Repuestos
                  </Tag>
                </Popover>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VehiculosHistorial;
