import { useParams, useSearchParams } from "react-router-dom";
import { FiUser } from "react-icons/fi";

import { actions, getAction, hasPermission } from "../../services/security";

import Card from "./../../components/ui/card";
import UsuariosForm from "./../../components/usuarios/usuarios-form";
import Header from "./../../components/ui/header";

const UsuariosDetailPage = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const CREAR = hasPermission(actions.UsuariosCrear);
  const EDITAR = hasPermission(actions.UsuariosEditar);
  const readonly = searchParams.get("view") === "readonly";

  const action = getAction(id, CREAR, EDITAR, readonly);
  const title = `${action} Usuario`;
  const icon = <FiUser />;
  const breadcrumb = [
    { title: "Usuarios", url: "/usuarios" },
    { title: action, url: `/usuarios/${id}` },
  ];

  return (
    <Card>
      <Header title={title} icon={icon} breadcrumb={breadcrumb} />
      <UsuariosForm id={id} readonly={readonly} />
    </Card>
  );
};

export default UsuariosDetailPage;
