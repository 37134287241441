/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import {
  Calendar as Calendard,
  Col,
  DatePicker,
  Form,
  Select,
  Row,
} from "antd";
import SimpleBar from "simplebar-react";
import moment from "moment";

import { currentMonth, firstDayOfMonth, today } from "../../services/forms";
import http from "./../../services/http";

import TableContext from "../../store/table-context";

import classes from "./calendar.module.scss";

const Calendar = (props) => {
  const [form] = Form.useForm();
  const context = useContext(TableContext);

  const { id, url, onClickDate, renderEvent } = props;

  const [current, setCurrent] = useState(firstDayOfMonth());
  const [mes, setMes] = useState(currentMonth());
  const [anio, setAnio] = useState(today());

  const [first, setFirst] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    const serialized = localStorage.getItem(id);
    if (!serialized) {
      setFirst(false);
      form.setFieldsValue({ mes: mes, anio: anio });
      return;
    }

    const settings = JSON.parse(serialized);
    context.updateFilters(settings.filters);
    form.setFieldsValue({ mes: mes, anio: anio });
    setFirst(false);
  }, []);

  useEffect(() => {
    async function getList() {
      if (first) {
        return;
      }

      let nUrl = `${url}?fecha=${current.format("DD/MM/YYYY")}`;
      for (const property in context.filters) {
        nUrl += `&${property}=${context.filters[property] ?? ""}`;
      }

      const response = await http.get(nUrl);

      if (response) {
        const data = response.data;
        setList(data);
      }

      var settings = {
        filters: context.filters,
      };
      localStorage.setItem(id, JSON.stringify(settings));
    }

    getList();
  }, [first, current, context.filters, context.reload, url]);

  const renderHeader = () => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={[16, 24]}>
          <Col span={16}></Col>
          <Col span={4}>
            <Form.Item label="Mes" name="mes">
              <Select onChange={onChangeMes}>
                <Select.Option value={1}>Enero</Select.Option>
                <Select.Option value={2}>Febrero</Select.Option>
                <Select.Option value={3}>Marzo</Select.Option>
                <Select.Option value={4}>Abril</Select.Option>
                <Select.Option value={5}>Mayo</Select.Option>
                <Select.Option value={6}>Junio</Select.Option>
                <Select.Option value={7}>Julio</Select.Option>
                <Select.Option value={8}>Agosto</Select.Option>
                <Select.Option value={9}>Septiembre</Select.Option>
                <Select.Option value={10}>Octubre</Select.Option>
                <Select.Option value={11}>Noviembre</Select.Option>
                <Select.Option value={12}>Diciembre</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Año" name="anio">
              <DatePicker
                picker="year"
                onChange={onChangeAnio}
                allowClear={false}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const onChangeMes = (value) => {
    setMes(value);
    form.setFieldsValue({ mes: value, anio: anio });
    const nCurrent = moment(`${anio.year()}-${value}-01`, "YYYY-MM-DD");
    setCurrent(nCurrent);
  };

  const onChangeAnio = (value) => {
    setAnio(value);
    form.setFieldsValue({ mes: mes, anio: value });
    const nCurrent = moment(`${value.year()}-${mes}-01`, "YYYY-MM-DD");
    setCurrent(nCurrent);
  };

  const onPanelChange = (value, _) => {
    const nMes = value.month() + 1;
    setMes(nMes);
    setAnio(value);
    form.setFieldsValue({ mes: nMes, anio: value });
    const nCurrent = moment(`${value.year()}-${nMes}-01`, "YYYY-MM-DD");
    setCurrent(nCurrent);
  };

  const renderCell = (date) => {
    let events = [];

    if (list.length > 0) {
      const nDate = date.format("DD/MM/YYYY");
      events = list.filter((event) => event.fecha === nDate);
    }

    return (
      <div className={classes.day} onClick={() => onClickDate(date)}>
        <div className={classes.date}>{date.date()}</div>
        <SimpleBar style={{ maxHeight: "105px", width: "100%" }}>
          <div className={classes.events}>
            {events.map((event, index) => renderEvent(event, index))}
          </div>
        </SimpleBar>
      </div>
    );
  };

  return (
    <Calendard
      value={current}
      headerRender={renderHeader}
      dateFullCellRender={renderCell}
      onPanelChange={onPanelChange}
    />
  );
};

export default Calendar;
