import {
  FiCalendar,
  FiFileText,
  FiMonitor,
  FiShield,
  FiUser,
} from "react-icons/fi";
import { BsCarFront } from "react-icons/bs";

import { actions, hasPermission } from "./../../services/security";

import InicioSection from "../../components/inicio/inicio-section";

const InicioPage = () => {
  const sections = [
    {
      title: "Extintores",
      links: [
        {
          title: "Vehículos",
          url: "/vehiculos",
          icon: <BsCarFront />,
          visible: hasPermission(actions.VehiculosVer),
        },
        {
          title: "Ordenes de Trabajo",
          url: "/ordenes-trabajo",
          icon: <FiFileText />,
          visible: hasPermission(actions.OrdenesTrabajoVer),
        },
        {
          title: "Turnos",
          url: "/turnos",
          icon: <FiCalendar />,
          visible: hasPermission(actions.TurnosVer),
        },
      ],
    },
    {
      title: "Seguridad",
      links: [
        {
          title: "Perfiles",
          url: "/perfiles",
          icon: <FiShield />,
          visible: hasPermission(actions.PerfilesVer),
        },
        {
          title: "Usuarios",
          url: "/usuarios",
          icon: <FiUser />,
          visible: hasPermission(actions.UsuariosVer),
        },
        {
          title: "Auditoría",
          url: "/auditorias",
          icon: <FiMonitor />,
          visible: hasPermission(actions.AuditoriasVer),
        },
      ],
    },
  ];

  return (
    <div>
      {sections.map(
        (section, index) =>
          section.links.filter((x) => x.visible).length > 0 && (
            <InicioSection key={index} item={section} />
          )
      )}
    </div>
  );
};

export default InicioPage;
