import { Col, Row } from "antd";

import ResetPasswordForm from "./../../components/auth/reset-password-form";

import image from "./../../assets/images/background.jpg";
import logo from "./../../assets/images/logo-horizontal.png";

import classes from "./ResetPassword.module.scss";

const ResetPasswordPage = () => {
  return (
    <Row>
      <Col sm={0} lg={12} xl={14}>
        <div
          className={classes.image}
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className={classes.overlay}>
            <img className={classes.logo} src={logo} alt="logo" />
          </div>
        </div>
      </Col>
      <Col sm={24} lg={12} xl={10}>
        <div className={classes.form}>
          <ResetPasswordForm />
        </div>
      </Col>
    </Row>
  );
};

export default ResetPasswordPage;
